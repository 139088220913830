import React from 'react';
import { string, shape, object } from 'prop-types';
import { Thumbnail } from '@andes/thumbnail';
import classnames from 'classnames';
import { Image } from 'nordic/image';
import { Pill } from '@andes/badge';

import { trackEvent } from '../../../../../services/tracking';

const namespace = 'quick-access-item';

const QuickAccessItem = ({
  tag,
  picture,
  permalink,
  text,
  track_event,
  className,
}) => {
  const { src } = picture;
  const lazyLoad = false;
  return (
    <div className={classnames(namespace, className)}>
      <div className={`${namespace}__content`} aria-hidden="true">
        <Thumbnail
          className={classnames(`${namespace}__icon`, tag ? `tag tag--${tag.color}` : '')}
          srLabel={text}
          badge={tag && <Pill color={tag.color} size="small" className={`${namespace}__pill`}>{tag.text}</Pill>}
        >
          <Image
            src={src}
            className={`${namespace}__image`}
            alt={text}
            lazyload={lazyLoad ? 'on' : 'off'}
            key="image"
          />
        </Thumbnail>
      </div>
      <p className={classnames(`${namespace}__label`, tag ? 'tag' : '')}>
        <a
          className={classnames(`${namespace}-anchor`)}
          href={permalink}
          onClick={() => trackEvent(track_event)}
        >
          {text}
        </a>
      </p>
    </div>);
};

QuickAccessItem.propTypes = {
  tag: shape({
    text: string,
    color: string,
  }),
  picture: shape({
    src: string.isRequired,
  }).isRequired,
  permalink: string,
  text: string,
  track_event: object,
  className: string,
};

export default QuickAccessItem;
