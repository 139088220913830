import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import Section from '../section';
import withTracker from '../with-tracker';
import QuickAccessItem from './components/quick-access-item/index';

const namespace = 'carousel-quick-access';
const SPACING = 16;

const QuickAccess = ({ type, elements }) => (
  <Section type={type}>
    <CarouselFree srLabel={type} className={namespace} spacing={SPACING}>
      {elements.map((key, index) => (
        <CarouselFreeSlide key={`${type}_${index.toString()}`}>
          <QuickAccessItem {...key} />
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  </Section>);

QuickAccess.propTypes = {
  type: string,
  elements: arrayOf(shape({})),
};

export default withTracker(QuickAccess);
